/* eslint-disable sort-exports/sort-exports */
/* eslint-disable sort-keys-fix/sort-keys-fix */
import Logo from './Logo';

export const baseColors = {
  white: '#fff',
  black: '#000',
  gray: [
    '#fff', // 0 index
    '#f8f8f8',
    '#dedede',
    '#979797',
    '#3d3d3d',
  ],
  red: '#f00101',
  beige: '#F8FAF2',
  freshGreen: '#8abd24',
  earthyGreen: '#536121',
  backgroundGreen: '#99BB39',
  livelyWhite: '#f8faf0',
  sun: '#f2d807',
  sea: '#50adc8',
  nature: '#146f67',
  sky: '#2b4390',
  earth: '#cd5b1b',
  backgroundLight: '#a0bb4f',
};

export const colors = {
  ...baseColors,
  superLightGrey: baseColors.gray[1],
  lightGray: baseColors.gray[2],
  darkGray: baseColors.gray[4],
  text: baseColors.earthyGreen,
  background: baseColors.livelyWhite,
  primaryWhite: baseColors.livelyWhite,
  primary: baseColors.freshGreen,
  primaryLightGreen: baseColors.freshGreen,
  primaryDarkGreen: baseColors.earthyGreen,
  primaryBeige: baseColors.beige,
  secondaryYellow: baseColors.sun,
  secondaryLightBlue: baseColors.sea,
  secondaryCyan: baseColors.nature,
  secondaryDarkBlue: baseColors.sky,
  secondaryOrange: baseColors.earth,
  secondary: baseColors.freshGreen,
  muted: baseColors.gray[1],
  success: baseColors.freshGreen,
  info: baseColors.sea,
  warning: baseColors.sun,
  danger: baseColors.red,
  light: baseColors.gray[2],
  dark: baseColors.gray[4],
  textMuted: baseColors.gray[3],
  backgroundLight: baseColors.backgroundLight,
  buttonGreen: baseColors.backgroundGreen,
};

export const space = [0, 0.25, 0.5, 1, 1.5, 3].map(n => `${n}rem`);

export const breakpoints = ['576px', '768px', '992px'];

export const fonts = {
  body: 'Montserrat, sans-serif',
  heading: 'Merriweather',
  quotes: 'Merriweather',
  hightlight: 'Montserrat Medium Italic',
};

export const fontWeights = {
  body: 400,
  heading: 700,
  headingAlt: 600,
  bold: 700,
  light: 300,
  normal: 400,
  display: 300,
};

export const fontSizes = [
  '0.75rem', // 12px (80%),
  '0.875rem',
  '1rem', // 16px
  '1.25rem',
  '1.5rem',
  '1.75rem',
  '2rem', // 32px
  '2.25rem',
  '2.5rem',
  '3.5rem', // 56px
  '4.5rem',
  '5.5rem',
  '6rem',
];

export const lineHeights = {
  body: 1.5,
  heading: 1.5,
  paragraph: 1.75,
};

export const sizes = {
  // container widths
  sm: '540px',
  md: '720px',
  lg: '1150px',
  xl: '1440px',
};

export const radii = {
  default: '0.25rem',
  sm: '0.2rem',
  lg: '0.3rem',
  pill: '50rem',
};

export const shadows = {
  default: '0 .5rem 1rem rgba(0, 0, 0, .15)',
  sm: '0 .125rem .25rem rgba(0, 0, 0, .075)',
  lg: '0 1rem 3rem rgba(0, 0, 0, .175)',
};

const heading = {
  fontFamily: fonts.heading,
  fontWeight: fontWeights.heading,
  lineHeight: lineHeights.heading,
  marginTop: '1rem',
  marginBottom: '1rem',
};

const display = {
  fontWeight: fontWeights.display,
  lineHeight: lineHeights.heading,
};

// variants
const typeStyles = {
  heading,
  display,
};

export const text = {
  caps: {
    textTransform: 'uppercase',
    letterSpacing: '0.2em',
  },
  heading: {
    fontFamily: fonts.heading,
    fontWeight: fontWeights.heading,
    lineHeight: lineHeights.heading,
    textTransform: 'uppercase',
  },
};

export const styles = {
  root: {
    fontFamily: fonts.body,
    lineHeight: lineHeights.body,
    fontWeight: fontWeights.body,
    backgroundColor: colors.white,
  },
  a: {
    color: colors.text,
    textUnderlineOffset: '8px',
    ':hover': {
      textDecoration: 'solid underline 2px',
      textUnderlineOffset: '9px',
      fontWeight: fontWeights.bold,
    },
  },
  rteHighlight: {
    color: colors.primaryLightGreen,
    textDecoration: 'underline',
    ':hover': {
      color: colors.primaryDarkGreen,
    },
  },
  p: {
    margin: '0',
    marginBottom: '1.7rem',
    lineHeight: lineHeights.paragraph,
    fontSize: fontSizes[2],
  },
  li: {
    bulletColor: colors.primaryDarkGreen,
    bulletBorderRadius: '5px',
  },
  h1: {
    ...heading,
    fontSize: fontSizes[8],
  },
  h2: {
    ...heading,
    fontSize: fontSizes[7],
  },
  h3: {
    ...heading,
    fontSize: fontSizes[6],
  },
  h4: {
    fontSize: fontSizes[2],
  },
  blockquote: {
    backgroundColor: colors.primaryLightGreen,
    color: colors.white,
    fontStyle: 'normal',
    textTransform: 'normal',
    fontWeight: 'bold',
  },
  table: {
    // todo
    width: '100%',
    marginBottom: '3rem',
    color: colors.gray[3],
    borderCollapse: 'collapse',
  },
  th: {
    verticalAlign: 'bottom',
    borderTopWidth: '2px',
    borderTopStyle: 'solid',
    borderTopColor: colors.gray[3],
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    borderBottomColor: colors.gray[3],
    padding: '.75rem',
    textAlign: 'inherit',
  },
  td: {
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.gray[3],
    verticalAlign: 'top',
    padding: '.75rem',
  },
  inlineCode: {
    color: colors.primaryDarkGreen,
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
  },
};

const buttonDefaults = {
  fontFamily: fonts.body,
  cursor: 'pointer',
  textTransform: 'normal',
  fontSize: '14px',
  fontWeight: fontWeights.bold,
  height: '48px',
  minWidth: '194px',
  borderRadius: '0px',
  transition:
    'background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), color 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
};

export const buttons = {
  primary: {
    ...buttonDefaults,
    backgroundColor: '#99BB39',
    color: colors.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.primaryBeige,
    ':hover': {
      backgroundColor: colors.primaryLightGreen,
    },
  },
  'primary-with-background': {
    ...buttonDefaults,
    backgroundColor: colors.white,
    color: colors.primaryDarkGreen,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.white,
    ':hover': {
      backgroundColor: 'transparent',
      color: colors.white,
    },
  },
  secondary: {
    ...buttonDefaults,
    backgroundColor: colors.primaryBeige,
    color: colors.primaryDarkGreen,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.primaryBeige,
    ':hover': {
      backgroundColor: 'transparent',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: colors.primaryBeige,
      color: colors.primaryBeige,
    },
  },
  tertiary: {
    ...buttonDefaults,
    backgroundColor: 'transparent',
    color: colors.primaryBeige,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.primaryBeige,
    ':hover': {
      backgroundColor: colors.primaryBeige,
      color: colors.primaryDarkGreen,
    },
  },
  disabled: {
    ...buttonDefaults,
    cursor: 'default',
    backgroundColor: colors.muted,
    color: colors.textMuted,
  },
};

export const header = {
  backgroundColor: colors.primaryBeige,
  color: colors.primaryDarkGreen,
  activeColor: colors.primaryLightGreen,
};

export const headerBottom = {
  backgroundColor: colors.primaryWhite,
  color: colors.primaryDarkGreen,
  activeColor: colors.primaryDarkGreen,
  fontWeight: '400',
  activeFontWeight: '700',
  fontSize: '14px',
};

export const mobileNavigation = {
  chevronColor: colors.darkGray,
  arrowColor: colors.lightGray,
};

export const footer = {
  backgroundColor: colors.white,
  color: colors.primaryDarkGreen,
  activeColor: colors.primaryLightGreen,
  minHeight: '160px',
};

export const cardCtas = {
  color: 'rgba(138, 188, 36, 0.8)',
  hovercolor: colors.primaryLightGreen,
  arrowColor: 'rgba(138, 188, 36, 0.8)',
  arrowHoverColor: colors.primaryLightGreen,
  textTransform: 'normal',
  fontSize: '14px',
  lineHeight: '14px',
  letterSpacing: '0px',
};

export const documentDownload = {
  color: 'rgba(138, 188, 36, 0.8)',
  hovercolor: colors.primaryLightGreen,
  arrowColor: 'rgba(138, 188, 36, 0.8)',
  arrowHoverColor: colors.primaryLightGreen,
  fontWeight: 'bold',
};

export const formInputs = {
  activeColor: colors.primaryLightGreen,
  borderRadius: '4px',
  radioInputBorderRadius: '50%',
  radioSpanBorderRadius: '50%',
};

export const categoryCards = {
  backgroundColor: colors.primaryLightGreen,
  titleFontWeight: 'bold',
};

export const search = {
  borderRadius: '0px',
  activeColor: colors.primaryLightGreen,
  fontWeight: 'normal',
  textTransform: 'normal',
};

export const ctaSection = {
  backgroundColor: colors.primaryLightGreen,
  color: colors.white,
  titleFontWeight: 'bold',
};

export const shortBanner = {
  backgroundColor: colors.primaryLightGreen,
  color: colors.white,
  titleTextTransform: 'none',
  titleFontWeight: 'bold',
};

export const upComingEvents = {
  backgroundColor: colors.primaryLightGreen,
  color: colors.white,
  titleFontWeight: 'bold',
};

export const infoSection = {
  backgroundColor: colors.primaryLightGreen,
  color: colors.white,
};

export const ingredients = {
  backgroundColor: colors.primaryLightGreen,
  color: colors.white,
  hoverColor: colors.primaryWhite,
};

export const salesPerson = {
  borderColor: colors.primaryBeige,
};

export const dealer = {
  textColor: colors.primaryLightGreen,
  titleFontWeight: 'bold',
};

export const navItems = {
  textTransform: 'normal',
  fontSize: '14px',
  fontWeight: 400,
  selectedFontWeight: 400,
  letterSpacing: '0px',
};

export const imageAndText = {
  backgroundColor: 'white',
  color: colors.primaryDarkGreen,
};

export const media = {
  titleFontWeight: 'bold',
  titleTextTransform: 'normal',
  backgroundColor: 'transparent',
};

export const table = {
  headerBackgroundColor: colors.primaryLightGreen,
  headerColor: colors.white,
  headerFontSize: '12px',
  headerLetterSpacing: '0px',
  scrollbarColor: colors.primaryWhite,
};

export const customBlockCards = {
  backgroundColor: colors.primaryLightGreen,
  titleFontWeight: 'bold',
};

export const adviceCardModule = {
  backgroundColor: colors.primaryLightGreen,
  titleFontWeight: 'bold',
};

export const contacts = {
  titleFontWeight: 'bold',
  office: {
    mapTextTransform: 'normal',
    mapTextFontSize: '14px',
    mapTextLetterSpacing: '0px',
    mapTextColor: colors.primaryLightGreen,
    mapTextFontWeight: 'bold',
    mapTextLineHeight: '2',
    openingHoursFontSize: '16px',
  },
};

export const heroSection = {
  titleFontWeight: 'bold',
};

export const newsListing = {
  titleFontWeight: 'bold',
};

export const productListing = {
  titleFontWeight: 'bold',
};

export const cookieBanner = {
  backgroundColor: colors.primaryLightGreen,
};

export default {
  name: 'BrandX',
  logo: Logo,
  breakpoints,
  text,
  colors,
  cookieBanner,
  space,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  sizes,
  shadows,
  radii,
  typeStyles,
  header,
  headerBottom,
  mobileNavigation,
  footer,
  styles,
  buttons,
  cardCtas,
  documentDownload,
  formInputs,
  categoryCards,
  search,
  ctaSection,
  upComingEvents,
  ingredients,
  salesPerson,
  infoSection,
  dealer,
  navItems,
  imageAndText,
  shortBanner,
  media,
  table,
  customBlockCards,
  adviceCardModule,
  contacts,
  heroSection,
  newsListing,
  productListing,
};
