import Alliance from './Alliance';
import Arieblok from './Arieblok';
import Arvesta from './Arvesta';
import AveveAgrarisch from './AveveAgrarisch';
import Avevebiochem from './Avevebiochem';
import Belcrop from './Belcrop';
import BrandX from './BrandX';
import Cofabel from './Cofabel';
import Dumoulin from './Dumoulin';
import Equifirst from './Equifirst';
import Globalfeedmill from './Globalfeedmill';
import Hafner from './Hafner';
import Hermoo from './Hermoo';
import Hobbyfirst from './Hobbyfirst';
import Hortiplan from './Hortiplan';
import Kasperfaunafood from './Kasperfaunafood';
import Lannoo from './Lannoo';
import Mifuma from './Mifuma';
import NaturalGranen from './NaturalGranen';
import Osmo from './Osmo';
import Palital from './Palital';
import Peka from './Peka';
import PentaTrading from './PentaTrading';
import Pomagro from './Pomagro';
import Sabe from './Sabe';
import Sanac from './Sanac';
import Scoriethom from './Scoriethom';
import Servagri from './Servagri';
import Spoormans from './Spoormans';
import Walagri from './Walagri';
import WimLybaert from './WimLybaert';

const themes: { [key: string]: any } = {
  Alliance,
  Arieblok,
  Arvesta,
  AveveAgrarisch,
  Avevebiochem,
  Belcrop,
  BrandX,
  Cofabel,
  Dumoulin,
  Equifirst,
  Globalfeedmill,
  Hafner,
  Hermoo,
  Hobbyfirst,
  Hortiplan,
  Kasperfaunafood,
  Lannoo,
  Mifuma,
  NaturalGranen,
  Osmo,
  Palital,
  Peka,
  PentaTrading,
  Pomagro,
  Sabe,
  Sanac,
  Scoriethom,
  Servagri,
  Spoormans,
  Walagri,
  WimLybaert,
};

export default themes;
