import React from 'react';

const Logo = () => (
  <svg
    width="116px"
    height="72px"
    viewBox="0 0 116 72"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Artboard</title>
    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Dumoulin" transform="translate(3.000000, 0.000000)">
        <polygon id="Fill-1" fill="#FFDD00" points="0 52.317 109.676 52.317 109.676 33.777 0 33.777" />
        <polygon
          id="Fill-2"
          fill="#0055B8"
          points="76.083 52.3203 34.114 52.3203 34.114 52.9093 45.745 52.9093 55.098 62.2623 64.452 52.9093 76.083 52.9093"
        />
        <path
          d="M9.7427,51.0107 C12.3667,51.0107 14.4467,50.1837 15.9787,48.5267 C17.3387,47.0547 18.0197,45.2577 18.0197,43.1357 C18.0197,40.7707 17.3447,38.8867 15.9907,37.4827 C14.5007,35.9407 12.3407,35.1697 9.5077,35.1697 L2.4857,35.1697 L2.4857,35.7647 C3.0917,35.8197 3.5167,35.9157 3.7637,36.0517 C4.1907,36.2877 4.4037,36.7077 4.4037,37.3107 L4.4037,48.8697 C4.4037,49.4957 4.1787,49.9267 3.7297,50.1637 C3.4767,50.2937 3.0607,50.3767 2.4857,50.4147 L2.4857,51.0107 L9.7427,51.0107 Z M8.1157,37.3107 C8.1157,36.8307 8.1757,36.5047 8.2957,36.3387 C8.4747,36.0787 8.8637,35.9497 9.4607,35.9497 C11.2717,35.9497 12.5577,36.9067 13.3207,38.8217 C13.7917,40.0117 14.0277,41.4347 14.0277,43.0897 C14.0277,45.8757 13.5887,47.7587 12.7147,48.7397 C11.8407,49.7187 10.7217,50.2087 9.3607,50.2087 C8.8597,50.2087 8.5327,50.1027 8.3797,49.8887 C8.2267,49.6757 8.1387,49.3277 8.1157,48.8467 L8.1157,37.3107 Z"
          id="Fill-3"
          fill="#0055B8"
        />
        <path
          d="M19.1411,39.3945 L24.8321,39.3945 L24.8321,39.8315 C24.1811,39.8535 23.7441,39.9565 23.5251,40.1415 C23.3051,40.3265 23.1971,40.7485 23.1971,41.4085 L23.1971,47.1575 C23.1971,48.0315 23.2971,48.7055 23.5011,49.1805 C23.8681,50.0655 24.5721,50.5065 25.6141,50.5065 C26.9081,50.5065 27.7841,50.0175 28.2391,49.0385 C28.4901,48.5005 28.6171,47.7485 28.6171,46.7805 L28.6171,41.5265 C28.6171,40.9105 28.5051,40.4865 28.2831,40.2545 C28.0621,40.0235 27.6271,39.8815 26.9791,39.8315 L26.9791,39.3945 L30.6821,39.3945 L30.6821,39.8315 C30.1431,39.9365 29.7881,40.0675 29.6161,40.2205 C29.4431,40.3745 29.3571,40.7285 29.3571,41.2835 L29.3571,46.7805 C29.3571,48.2425 29.0121,49.3685 28.3211,50.1625 C27.6291,50.9575 26.5431,51.3545 25.0631,51.3545 C23.1281,51.3545 21.8031,50.7645 21.0911,49.5825 C20.7121,48.9575 20.5231,48.1485 20.5231,47.1575 L20.5231,41.4085 C20.5231,40.7585 20.4401,40.3515 20.2711,40.1835 C20.1041,40.0155 19.7271,39.8975 19.1411,39.8315 L19.1411,39.3945 Z"
          id="Fill-5"
          fill="#0055B8"
        />
        <path
          d="M31.5977,51.0107 L31.5977,50.5737 C32.2397,50.5697 32.6567,50.4517 32.8477,50.2267 C33.0397,49.9987 33.1357,49.6587 33.1357,49.2067 L33.1357,41.0467 C33.1357,40.5777 33.0267,40.2697 32.8067,40.1207 C32.5867,39.9737 32.1977,39.8747 31.6397,39.8307 L31.6397,39.3947 L35.8827,39.3947 L39.1977,47.3507 L39.3387,47.3507 L42.6357,39.3947 L46.8797,39.3947 L46.8797,39.8307 C46.3097,39.8747 45.9207,39.9837 45.7137,40.1547 C45.5037,40.3247 45.3997,40.7417 45.3997,41.4087 L45.3997,49.0047 C45.3997,49.6367 45.5037,50.0507 45.7137,50.2477 C45.9207,50.4427 46.3097,50.5517 46.8797,50.5737 L46.8797,51.0107 L41.2867,51.0107 L41.2867,50.5737 C41.7867,50.5577 42.1617,50.4717 42.4137,50.3197 C42.6667,50.1637 42.7917,49.8267 42.7917,49.3067 L42.7917,40.9057 L42.6687,40.9057 L38.5397,51.0107 L38.0877,51.0107 L33.9167,41.1077 L33.8427,41.1077 L33.8427,48.8867 C33.8427,49.4577 33.9477,49.8737 34.1557,50.1387 C34.3647,50.4007 34.8247,50.5467 35.5377,50.5737 L35.5377,51.0107 L31.5977,51.0107 Z"
          id="Fill-7"
          fill="#0055B8"
        />
        <path
          d="M49.6397,40.8965 C48.5317,42.0545 47.9777,43.5345 47.9777,45.3355 C47.9777,46.9695 48.5067,48.3635 49.5657,49.5155 C50.7007,50.7425 52.1677,51.3535 53.9667,51.3535 C55.6607,51.3535 57.0737,50.7945 58.2017,49.6745 C59.3597,48.5295 59.9377,47.0525 59.9377,45.2445 C59.9377,43.4355 59.3597,41.9595 58.2017,40.8125 C57.0777,39.6945 55.6657,39.1345 53.9667,39.1345 C52.1997,39.1345 50.7597,39.7225 49.6397,40.8965 M51.3597,48.4845 C51.1067,47.6615 50.9797,46.5825 50.9797,45.2445 C50.9797,43.9295 51.1037,42.8615 51.3507,42.0385 C51.8227,40.4885 52.6937,39.7125 53.9667,39.7125 C54.8827,39.7125 55.6077,40.1775 56.1417,41.1065 C56.6757,42.0355 56.9437,43.4145 56.9437,45.2445 C56.9437,46.5765 56.8177,47.6565 56.5667,48.4845 C56.0937,50.0115 55.2267,50.7755 53.9667,50.7755 C52.6937,50.7755 51.8247,50.0115 51.3597,48.4845"
          id="Fill-9"
          fill="#0055B8"
        />
        <path
          d="M60.9785,39.3945 L66.6695,39.3945 L66.6695,39.8315 C66.0175,39.8535 65.5815,39.9565 65.3625,40.1415 C65.1425,40.3265 65.0345,40.7485 65.0345,41.4085 L65.0345,47.1575 C65.0345,48.0315 65.1335,48.7055 65.3375,49.1805 C65.7065,50.0655 66.4095,50.5065 67.4515,50.5065 C68.7455,50.5065 69.6215,50.0175 70.0765,49.0385 C70.3275,48.5005 70.4545,47.7485 70.4545,46.7805 L70.4545,41.5265 C70.4545,40.9105 70.3435,40.4865 70.1205,40.2545 C69.8995,40.0235 69.4625,39.8815 68.8165,39.8315 L68.8165,39.3945 L72.5185,39.3945 L72.5185,39.8315 C71.9815,39.9365 71.6255,40.0675 71.4535,40.2205 C71.2805,40.3745 71.1945,40.7285 71.1945,41.2835 L71.1945,46.7805 C71.1945,48.2425 70.8475,49.3685 70.1585,50.1625 C69.4665,50.9575 68.3805,51.3545 66.9005,51.3545 C64.9645,51.3545 63.6405,50.7645 62.9275,49.5825 C62.5495,48.9575 62.3605,48.1485 62.3605,47.1575 L62.3605,41.4085 C62.3605,40.7585 62.2775,40.3515 62.1085,40.1835 C61.9425,40.0155 61.5655,39.8975 60.9785,39.8315 L60.9785,39.3945 Z"
          id="Fill-11"
          fill="#0055B8"
        />
        <path
          d="M73.5088,51.0107 L73.5088,50.5737 C73.9708,50.5577 74.2978,50.4957 74.4948,50.3897 C74.8308,50.2097 74.9978,49.8687 74.9978,49.3657 L74.9978,41.0467 C74.9978,40.5437 74.8378,40.2057 74.5208,40.0327 C74.3338,39.9257 73.9978,39.8587 73.5088,39.8307 L73.5088,39.3947 L79.3978,39.3947 L79.3978,39.8307 C78.7128,39.8537 78.2548,39.9507 78.0288,40.1247 C77.8018,40.2977 77.6868,40.7267 77.6868,41.4087 L77.6868,49.5257 C77.6868,49.9067 77.7838,50.1567 77.9798,50.2807 C78.1748,50.4037 78.5538,50.4647 79.1178,50.4647 C80.1318,50.4647 80.9848,50.2367 81.6718,49.7817 C82.3618,49.3247 82.9528,48.4377 83.4528,47.1157 L83.9468,47.1157 L83.2648,51.0107 L73.5088,51.0107 Z"
          id="Fill-13"
          fill="#0055B8"
        />
        <path
          d="M84.8799,51.0107 L84.8799,50.5737 C85.3579,50.5627 85.6989,50.5057 85.9089,50.3977 C86.2489,50.2187 86.4179,49.8737 86.4179,49.3657 L86.4179,41.0467 C86.4179,40.5377 86.2579,40.1977 85.9329,40.0247 C85.7349,39.9167 85.3849,39.8537 84.8799,39.8307 L84.8799,39.3947 L90.7709,39.3947 L90.7709,39.8307 C90.2429,39.8537 89.8819,39.9147 89.6849,40.0157 C89.3559,40.1887 89.1909,40.5317 89.1909,41.0467 L89.1909,49.3657 C89.1909,49.8477 89.3219,50.1687 89.5849,50.3307 C89.8489,50.4917 90.2429,50.5737 90.7709,50.5737 L90.7709,51.0107 L84.8799,51.0107 Z"
          id="Fill-15"
          fill="#0055B8"
        />
        <path
          d="M94.8506,49.6367 C94.6556,49.3387 94.5596,48.8327 94.5596,48.1157 L94.5596,38.9127 L94.7156,38.9127 L104.8226,51.4447 L105.4616,51.4447 L105.4616,37.6427 C105.4616,36.9267 105.5826,36.4647 105.8266,36.2577 C106.0686,36.0517 106.5486,35.8867 107.2676,35.7667 L107.2676,35.1707 L102.3206,35.1707 L102.3206,35.7667 C103.1736,35.8347 103.7406,36.0377 104.0256,36.3767 C104.3106,36.7167 104.4516,37.2827 104.4516,38.0767 L104.4516,45.0477 L104.3396,45.0477 L96.4436,35.1707 L91.5766,35.1707 L91.5766,35.7667 C91.8596,35.8037 92.0836,35.8937 92.2476,36.0347 C92.4126,36.1767 92.8456,36.6467 93.5496,37.4487 L93.5496,48.5507 C93.5496,49.1537 93.4256,49.6087 93.1786,49.9177 C92.9336,50.2267 92.3816,50.3927 91.5306,50.4147 L91.5306,51.0097 L96.7356,51.0097 L96.7356,50.4147 C95.7846,50.3767 95.1586,50.1177 94.8506,49.6367"
          id="Fill-17"
          fill="#0055B8"
        />
        <path
          d="M21.397,69.3018 C21.418,69.7898 21.703,70.1138 22.204,70.1138 C22.489,70.1138 22.767,69.9838 22.878,69.7218 L23.983,69.7218 C23.768,70.5478 22.996,70.9058 22.19,70.9058 C21.015,70.9058 20.215,70.2038 20.215,69.0058 C20.215,67.9118 21.098,67.1278 22.162,67.1278 C23.455,67.1278 24.102,68.0898 24.046,69.3018 L21.397,69.3018 Z M22.864,68.6478 C22.843,68.2488 22.558,67.9188 22.162,67.9188 C21.745,67.9188 21.467,68.2148 21.397,68.6478 L22.864,68.6478 Z"
          id="Fill-19"
          fill="#0055B8"
        />
        <polygon
          id="Fill-21"
          fill="#0055B8"
          points="25.3057 68.9307 24.1587 67.2307 25.4657 67.2307 25.9727 68.0837 26.4807 67.2307 27.7317 67.2307 26.6057 68.9097 27.8847 70.8027 26.5777 70.8027 25.9517 69.7707 25.3607 70.8027 24.0537 70.8027"
        />
        <path
          d="M28.0943,67.2305 L29.2213,67.2305 L29.2213,67.6435 L29.2343,67.6435 C29.4643,67.3065 29.8123,67.1265 30.2293,67.1265 C31.3623,67.1265 31.8423,68.0905 31.8423,69.0955 C31.8423,70.0245 31.3423,70.9055 30.3193,70.9055 C29.8743,70.9055 29.5333,70.7405 29.2903,70.4175 L29.2763,70.4175 L29.2763,72.0005 L28.0943,72.0005 L28.0943,67.2305 Z M29.2343,68.9785 C29.2343,69.6055 29.4163,70.0105 29.9503,70.0105 C30.4793,70.0105 30.6603,69.4665 30.6603,68.9785 C30.6603,68.5245 30.4793,68.0215 29.9583,68.0215 C29.6383,68.0215 29.2343,68.2425 29.2343,68.9785 L29.2343,68.9785 Z"
          id="Fill-23"
          fill="#0055B8"
        />
        <path
          d="M33.3447,69.3018 C33.3657,69.7898 33.6507,70.1138 34.1517,70.1138 C34.4367,70.1138 34.7147,69.9838 34.8257,69.7218 L35.9307,69.7218 C35.7157,70.5478 34.9437,70.9058 34.1377,70.9058 C32.9627,70.9058 32.1627,70.2038 32.1627,69.0058 C32.1627,67.9118 33.0457,67.1278 34.1097,67.1278 C35.4037,67.1278 36.0497,68.0898 35.9937,69.3018 L33.3447,69.3018 Z M34.8117,68.6478 C34.7917,68.2488 34.5067,67.9188 34.1097,67.9188 C33.6927,67.9188 33.4147,68.2148 33.3447,68.6478 L34.8117,68.6478 Z"
          id="Fill-26"
          fill="#0055B8"
        />
        <path
          d="M36.4888,67.2305 L37.6358,67.2305 L37.6358,67.8565 L37.6498,67.8565 C37.8238,67.4025 38.2278,67.1615 38.7348,67.1615 C38.8248,67.1615 38.9228,67.1685 39.0128,67.1885 L39.0128,68.2555 C38.8598,68.2145 38.7208,68.1875 38.5608,68.1875 C37.9768,68.1875 37.6708,68.5855 37.6708,69.0335 L37.6708,70.8015 L36.4888,70.8015 L36.4888,67.2305 Z"
          id="Fill-28"
          fill="#0055B8"
        />
        <path
          d="M41.4468,67.9873 L40.7168,67.9873 L40.7168,69.6113 C40.7168,69.8933 40.8838,69.9553 41.1408,69.9553 C41.2388,69.9553 41.3418,69.9423 41.4468,69.9423 L41.4468,70.8013 C41.2308,70.8083 41.0158,70.8373 40.8008,70.8373 C39.7918,70.8373 39.5348,70.5483 39.5348,69.5773 L39.5348,67.9873 L38.9358,67.9873 L38.9358,67.2303 L39.5348,67.2303 L39.5348,66.1493 L40.7168,66.1493 L40.7168,67.2303 L41.4468,67.2303 L41.4468,67.9873 Z"
          id="Fill-30"
          fill="#0055B8"
        />
        <path
          d="M43.929,66.782 L45.111,66.782 L45.111,65.887 L43.929,65.887 L43.929,66.782 Z M43.93,70.801 L45.112,70.801 L45.112,67.23 L43.93,67.23 L43.93,70.801 Z"
          id="Fill-32"
          fill="#0055B8"
        />
        <path
          d="M45.8076,67.2305 L46.9546,67.2305 L46.9546,67.7185 L46.9686,67.7185 C47.1846,67.3545 47.6716,67.1265 48.1016,67.1265 C49.3046,67.1265 49.4026,67.9945 49.4026,68.5105 L49.4026,70.8015 L48.2206,70.8015 L48.2206,69.0745 C48.2206,68.5855 48.2766,68.0905 47.6286,68.0905 C47.1846,68.0905 46.9896,68.4625 46.9896,68.8545 L46.9896,70.8015 L45.8076,70.8015 L45.8076,67.2305 Z"
          id="Fill-34"
          fill="#0055B8"
        />
        <path
          d="M52.0527,68.332 C52.0737,67.863 52.2967,67.561 52.6157,67.375 C52.9357,67.196 53.3527,67.127 53.7627,67.127 C54.6187,67.127 55.4467,67.312 55.4467,68.324 L55.4467,69.887 C55.4467,70.189 55.4467,70.52 55.5847,70.803 L54.3957,70.803 C54.3547,70.691 54.3407,70.582 54.3267,70.465 C54.0207,70.781 53.5687,70.906 53.1377,70.906 C52.4487,70.906 51.9067,70.561 51.9067,69.825 C51.9067,68.662 53.1857,68.751 54.0067,68.587 C54.2077,68.545 54.3197,68.476 54.3197,68.256 C54.3197,67.987 53.9927,67.884 53.7487,67.884 C53.4227,67.884 53.2137,68.029 53.1577,68.332 L52.0527,68.332 Z M53.5827,70.183 C54.1457,70.183 54.3327,69.866 54.3047,69.13 C54.1387,69.232 53.8327,69.254 53.5747,69.322 C53.3107,69.385 53.0897,69.494 53.0897,69.776 C53.0897,70.066 53.3187,70.183 53.5827,70.183 L53.5827,70.183 Z"
          id="Fill-35"
          fill="#0055B8"
        />
        <path
          d="M56.086,67.2305 L57.233,67.2305 L57.233,67.7185 L57.248,67.7185 C57.463,67.3545 57.95,67.1265 58.381,67.1265 C59.584,67.1265 59.682,67.9945 59.682,68.5105 L59.682,70.8015 L58.499,70.8015 L58.499,69.0745 C58.499,68.5855 58.555,68.0905 57.908,68.0905 C57.463,68.0905 57.268,68.4625 57.268,68.8545 L57.268,70.8015 L56.086,70.8015 L56.086,67.2305 Z"
          id="Fill-36"
          fill="#0055B8"
        />
        <path
          d="M60.37,66.782 L61.552,66.782 L61.552,65.887 L60.37,65.887 L60.37,66.782 Z M60.37,70.801 L61.552,70.801 L61.552,67.23 L60.37,67.23 L60.37,70.801 Z"
          id="Fill-37"
          fill="#0055B8"
        />
        <path
          d="M62.2481,67.2305 L63.3951,67.2305 L63.3951,67.7185 L63.4091,67.7185 C63.6251,67.3615 64.0071,67.1265 64.4451,67.1265 C64.8971,67.1265 65.3011,67.2715 65.4951,67.7055 C65.7801,67.3275 66.1351,67.1265 66.6221,67.1265 C67.7691,67.1265 67.8941,67.9875 67.8941,68.6685 L67.8941,70.8015 L66.7121,70.8015 L66.7121,68.7035 C66.7121,68.3175 66.5241,68.0905 66.2191,68.0905 C65.7111,68.0905 65.6621,68.4765 65.6621,69.0545 L65.6621,70.8015 L64.4801,70.8015 L64.4801,68.7725 C64.4801,68.3525 64.3551,68.0905 64.0361,68.0905 C63.6101,68.0905 63.4311,68.3325 63.4311,69.0605 L63.4311,70.8015 L62.2481,70.8015 L62.2481,67.2305 Z"
          id="Fill-38"
          fill="#0055B8"
        />
        <path
          d="M68.5,68.332 C68.521,67.863 68.744,67.561 69.063,67.375 C69.384,67.196 69.801,67.127 70.211,67.127 C71.066,67.127 71.894,67.312 71.894,68.324 L71.894,69.887 C71.894,70.189 71.894,70.52 72.032,70.803 L70.844,70.803 C70.802,70.691 70.788,70.582 70.774,70.465 C70.468,70.781 70.016,70.906 69.585,70.906 C68.896,70.906 68.354,70.561 68.354,69.825 C68.354,68.662 69.634,68.751 70.454,68.587 C70.656,68.545 70.768,68.476 70.768,68.256 C70.768,67.987 70.44,67.884 70.196,67.884 C69.87,67.884 69.662,68.029 69.605,68.332 L68.5,68.332 Z M70.03,70.183 C70.594,70.183 70.781,69.866 70.753,69.13 C70.587,69.232 70.28,69.254 70.023,69.322 C69.759,69.385 69.536,69.494 69.536,69.776 C69.536,70.066 69.766,70.183 70.03,70.183 L70.03,70.183 Z"
          id="Fill-39"
          fill="#0055B8"
        />
        <polygon id="Fill-40" fill="#0055B8" points="72.575 70.803 73.758 70.803 73.758 65.888 72.575 65.888" />
        <path
          d="M76.7627,67.9873 L76.1917,67.9873 L76.1917,67.2303 L76.7627,67.2303 C76.7627,66.2043 77.1237,65.8873 78.1317,65.8873 C78.3197,65.8873 78.5147,65.9013 78.7017,65.9093 L78.7017,66.7343 C78.5907,66.7203 78.4867,66.7143 78.3817,66.7143 C78.1117,66.7143 77.9447,66.7483 77.9447,67.0723 L77.9447,67.2303 L78.6537,67.2303 L78.6537,67.9873 L77.9447,67.9873 L77.9447,70.8013 L76.7627,70.8013 L76.7627,67.9873 Z"
          id="Fill-41"
          fill="#0055B8"
        />
        <path
          d="M79.9473,69.3018 C79.9683,69.7898 80.2533,70.1138 80.7543,70.1138 C81.0393,70.1138 81.3163,69.9838 81.4273,69.7218 L82.5333,69.7218 C82.3183,70.5478 81.5463,70.9058 80.7403,70.9058 C79.5643,70.9058 78.7653,70.2038 78.7653,69.0058 C78.7653,67.9118 79.6483,67.1278 80.7123,67.1278 C82.0063,67.1278 82.6513,68.0898 82.5963,69.3018 L79.9473,69.3018 Z M81.4143,68.6478 C81.3933,68.2488 81.1083,67.9188 80.7123,67.9188 C80.2953,67.9188 80.0163,68.2148 79.9473,68.6478 L81.4143,68.6478 Z"
          id="Fill-42"
          fill="#0055B8"
        />
        <path
          d="M84.0576,69.3018 C84.0786,69.7898 84.3636,70.1138 84.8646,70.1138 C85.1496,70.1138 85.4266,69.9838 85.5376,69.7218 L86.6436,69.7218 C86.4276,70.5478 85.6566,70.9058 84.8506,70.9058 C83.6746,70.9058 82.8746,70.2038 82.8746,69.0058 C82.8746,67.9118 83.7586,67.1278 84.8226,67.1278 C86.1156,67.1278 86.7616,68.0898 86.7066,69.3018 L84.0576,69.3018 Z M85.5246,68.6478 C85.5026,68.2488 85.2176,67.9188 84.8226,67.9188 C84.4046,67.9188 84.1266,68.2148 84.0576,68.6478 L85.5246,68.6478 Z"
          id="Fill-43"
          fill="#0055B8"
        />
        <path
          d="M90.7334,70.8027 L89.6064,70.8027 L89.6064,70.3897 L89.5924,70.3897 C89.3634,70.7267 89.0154,70.9057 88.5984,70.9057 C87.4644,70.9057 86.9854,69.9417 86.9854,68.9377 C86.9854,68.0077 87.4854,67.1277 88.5084,67.1277 C88.9534,67.1277 89.2944,67.2917 89.5374,67.6157 L89.5514,67.6157 L89.5514,65.8877 L90.7334,65.8877 L90.7334,70.8027 Z M88.1684,68.9787 C88.1684,69.4667 88.3484,70.0107 88.8904,70.0107 C89.4674,70.0107 89.5924,69.4667 89.5924,69.0137 C89.5924,68.4967 89.4054,68.0217 88.8904,68.0217 C88.3484,68.0217 88.1684,68.5247 88.1684,68.9787 L88.1684,68.9787 Z"
          id="Fill-44"
          fill="#0055B8"
        />
        <path
          d="M75.0342,16.1323 C75.2712,16.4273 75.5512,16.4923 75.7412,16.4923 C76.2962,16.4923 76.9642,15.9983 77.5272,15.1733 C78.5352,13.6893 77.6592,10.9613 77.6212,10.8443 L77.5782,10.7123 L77.4682,10.7993 C77.4442,10.8203 77.3082,10.9303 77.1132,11.1063 C77.0442,10.8283 76.9882,10.6563 76.9882,10.6563 C76.9882,10.6563 73.8822,13.1743 74.2272,14.9713 C74.3672,15.6913 74.6652,16.0483 75.0342,16.1323"
          id="Fill-45"
          fill="#060302"
        />
        <path
          d="M76.6319,17.1216 C76.6699,18.4976 78.7379,19.9826 79.7449,20.6256 C80.2979,21.0076 80.7339,21.2546 80.7839,21.2826 L80.9119,21.3546 L80.9269,21.2076 C80.9399,21.0876 81.2049,18.2746 79.8919,16.9686 C79.2469,16.3276 78.5849,15.9576 78.0599,15.9356 C77.2389,15.5926 76.5969,15.8826 76.6319,17.1216"
          id="Fill-46"
          fill="#060302"
        />
        <path
          d="M79.7705,16.2119 C79.8125,16.2389 79.8615,16.2539 79.9075,16.2759 C80.0045,16.3609 80.1155,16.4459 80.2505,16.5319 C80.5285,16.7049 80.8795,16.7939 81.2975,16.7939 C82.9685,16.7939 85.1765,15.4089 85.2695,15.3499 L85.3815,15.2779 L85.2795,15.1929 C85.2125,15.1389 83.7825,13.9769 82.3345,13.7559 C81.8135,13.5969 81.2695,13.5279 80.7735,13.6629 C79.1015,14.1169 78.2455,15.2579 79.7705,16.2119"
          id="Fill-47"
          fill="#060302"
        />
        <path
          d="M76.7725,21.6743 C76.7735,21.6743 76.7765,21.6763 76.7765,21.6763 L76.9065,21.7493 L76.9205,21.6023 C76.9335,21.4833 77.2015,18.6683 75.8865,17.3623 C75.2415,16.7213 74.5795,16.3533 74.0545,16.3303 C73.2345,15.9873 72.5915,16.2783 72.6265,17.5163 C72.6265,17.5203 72.6265,17.5253 72.6265,17.5293 L71.7925,16.6933 C72.3325,16.6523 72.9635,16.1723 73.5015,15.3823 C74.5115,13.9003 73.6335,11.1703 73.5965,11.0543 L73.5525,10.9213 L73.4435,11.0093 C73.4185,11.0303 73.2835,11.1403 73.0875,11.3163 C73.0185,11.0373 72.9615,10.8663 72.9615,10.8663 C72.9615,10.8663 69.9605,13.3043 70.1945,15.0963 L70.1805,15.0823 L70.1805,15.0813 L69.8455,14.7483 L69.8385,14.7403 C70.2075,13.2513 69.6025,11.3613 69.5715,11.2653 L69.5285,11.1323 L69.4185,11.2203 C69.3935,11.2403 69.2585,11.3513 69.0625,11.5273 C68.9935,11.2483 68.9375,11.0773 68.9375,11.0773 C68.9375,11.0773 68.2355,11.6483 67.5365,12.4383 L55.0985,0.0003 L32.1445,22.9533 L32.1265,22.9723 C28.5285,24.0393 25.2005,25.2773 21.7045,26.8903 C19.3845,27.9733 16.9075,28.9433 15.3835,31.0133 C15.2725,31.1693 15.3085,31.3803 15.4635,31.4823 C15.5545,31.5423 15.6645,31.5453 15.7635,31.5123 C15.7635,31.5173 15.7625,31.5213 15.7635,31.5263 C15.7845,31.6383 15.8495,31.7343 15.9445,31.7973 C16.0145,31.8423 16.0975,31.8683 16.1835,31.8683 C16.3325,31.8683 16.4735,31.7943 16.5585,31.6743 C17.8215,29.9723 19.7935,29.0773 21.6995,28.2143 C21.9775,28.0883 22.2545,27.9623 22.5305,27.8333 C25.1265,26.6303 27.6505,25.6303 30.3745,24.7243 L23.9665,31.1323 L26.6145,33.7803 L83.5825,33.7803 L86.2305,31.1323 L76.7725,21.6743 Z"
          id="Fill-48"
          fill="#0055B8"
        />
        <polygon
          id="Fill-49"
          fill="#FFDD00"
          points="62.7588 28.5596 65.0508 28.4456 63.6128 29.5406 64.9818 31.0986 62.7408 30.2076 61.3008 31.2796 61.3758 29.6516 59.1018 28.7386 61.3878 28.6246 61.4468 27.0416"
        />
        <polygon
          id="Fill-50"
          fill="#FFDD00"
          points="71.457 26.2617 74.757 26.0997 72.689 27.6757 74.656 29.9147 71.434 28.6337 69.363 30.1767 69.47 27.8337 66.194 26.5227 69.486 26.3587 69.571 24.0767"
        />
        <polygon
          id="Fill-51"
          fill="#FFDD00"
          points="53.6885 27.2617 55.9835 27.1487 54.5475 28.2447 55.9145 29.8007 53.6735 28.9127 52.2345 29.9837 52.3095 28.3557 50.0345 27.4447 52.3215 27.3297 52.3795 25.7457"
        />
        <polygon
          id="Fill-52"
          fill="#FFDD00"
          points="43.2988 17.4204 44.5868 17.3554 43.7778 17.9704 44.5488 18.8424 43.2888 18.3454 42.4848 18.9444 42.5258 18.0314 41.2498 17.5214 42.5308 17.4584 42.5658 16.5664"
        />
        <polygon
          id="Fill-53"
          fill="#FFDD00"
          points="45.7671 23.9355 47.5091 23.8525 46.4151 24.6825 47.4561 25.8665 45.7541 25.1885 44.6571 26.0015 44.7161 24.7665 42.9881 24.0745 44.7241 23.9885 44.7691 22.7805"
        />
        <path
          d="M16.4697,31.6011 C17.9297,29.6341 20.2677,28.7611 22.4777,27.7321 C26.3977,25.9161 30.0927,24.5921 34.2227,23.4241 C42.7377,21.0381 50.9667,19.3901 59.4767,18.3651 C59.6717,18.3361 59.8117,18.1681 59.7977,17.9821 C59.7717,17.7991 59.6067,17.6701 59.4157,17.6921 C50.8577,18.7271 42.6057,20.3801 34.0457,22.7881 C29.9017,23.9461 26.1697,25.2891 22.2267,27.1101 C19.9077,28.1921 17.4307,29.1621 15.9067,31.2331 C15.7947,31.3891 15.8317,31.6001 15.9877,31.7001 C16.1417,31.8041 16.3597,31.7581 16.4697,31.6011"
          id="Fill-54"
          fill="#FFDD00"
        />
        <path
          d="M80.8086,21.1738 C80.8086,21.1738 81.0966,18.3128 79.8056,17.0278 C78.5156,15.7448 77.1016,15.5188 77.1536,17.3408 C77.2046,19.1618 80.8086,21.1738 80.8086,21.1738"
          id="Fill-55"
          fill="#FFDD00"
        />
        <path
          d="M85.1914,15.2559 C85.1914,15.2559 82.9664,13.4279 81.2954,13.8829 C79.6224,14.3349 78.7664,15.4779 80.2934,16.4319 C81.8194,17.3849 85.1914,15.2559 85.1914,15.2559"
          id="Fill-56"
          fill="#FFDD00"
        />
        <path
          d="M76.8037,21.5688 C76.8037,21.5688 77.0927,18.7058 75.8007,17.4218 C74.5097,16.1398 73.0967,15.9138 73.1487,17.7358 C73.1997,19.5568 76.8037,21.5688 76.8037,21.5688"
          id="Fill-57"
          fill="#FFDD00"
        />
        <path
          d="M72.7988,21.9624 C72.7988,21.9624 73.0868,19.1014 71.7958,17.8174 C70.5038,16.5334 69.0928,16.3094 69.1428,18.1304 C69.1948,19.9504 72.7988,21.9624 72.7988,21.9624"
          id="Fill-58"
          fill="#FFDD00"
        />
        <path
          d="M68.794,22.3574 C68.794,22.3574 69.082,19.4964 67.79,18.2114 C66.5,16.9284 65.087,16.7034 65.139,18.5244 C65.189,20.3454 68.794,22.3574 68.794,22.3574"
          id="Fill-59"
          fill="#FFDD00"
        />
        <path
          d="M64.7881,22.752 C64.7881,22.752 65.0761,19.891 63.7851,18.607 C62.4951,17.323 61.0811,17.097 61.1321,18.919 C61.1831,20.74 64.7881,22.752 64.7881,22.752"
          id="Fill-60"
          fill="#FFDD00"
        />
        <path
          d="M77.5098,10.876 C77.5098,10.876 78.4168,13.647 77.4268,15.103 C76.4368,16.558 75.0968,16.991 74.7488,15.193 C74.4038,13.393 77.5098,10.876 77.5098,10.876"
          id="Fill-61"
          fill="#FFDD00"
        />
        <path
          d="M73.4844,11.0854 C73.4844,11.0854 74.3924,13.8584 73.4014,15.3134 C72.4114,16.7684 71.0714,17.2014 70.7244,15.4024 C70.3774,13.6034 73.4844,11.0854 73.4844,11.0854"
          id="Fill-62"
          fill="#FFDD00"
        />
        <path
          d="M69.46,11.2964 C69.46,11.2964 70.367,14.0674 69.376,15.5244 C68.387,16.9794 67.046,17.4124 66.699,15.6134 C66.354,13.8134 69.46,11.2964 69.46,11.2964"
          id="Fill-63"
          fill="#FFDD00"
        />
        <path
          d="M65.4336,11.5073 C65.4336,11.5073 66.3416,14.2783 65.3506,15.7353 C64.3606,17.1903 63.0206,17.6233 62.6736,15.8243 C62.3276,14.0243 65.4336,11.5073 65.4336,11.5073"
          id="Fill-64"
          fill="#FFDD00"
        />
        <path
          d="M61.4092,11.7173 C61.4092,11.7173 62.3162,14.4903 61.3262,15.9453 C60.3362,17.4003 58.9962,17.8333 58.6482,16.0343 C58.3022,14.2363 61.4092,11.7173 61.4092,11.7173"
          id="Fill-65"
          fill="#FFDD00"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
