import React from 'react';

import locales from './locales';
import { GlobalfeedmillMessages, de, en, es, fr, nl, pl, pt } from './messages';

export { default as flags } from './flags';

const defaultLocale = process.env.DEFAULT_LOCALE || locales[0];

// TODO: Make dynamic for all brands
export const getTranslations = (locale: string, brandTag: string) => {
  if (brandTag !== 'Globalfeedmill') {
    return messages[locale];
  }

  return {
    ...messages[locale],
    ...GlobalfeedmillMessages,
  };
};
export const IntlContext = React.createContext(defaultLocale);

export const IntlContextConsumer = IntlContext.Consumer;
export const IntlContextProvider = IntlContext.Provider;
export { default as locales } from './locales';

export const messages: Record<string, Record<string, string>> = {
  de,
  en,
  es,
  fr,
  nl,
  pl,
  pt,
};
