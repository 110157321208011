/* eslint-disable sort-exports/sort-exports */
/* eslint-disable sort-keys-fix/sort-keys-fix */
import Logo from './Logo';

export const baseColors = {
  white: '#fff',
  black: '#000',
  gray: [
    '#fff', // 0 index
    '#f8f8f8',
    '#dedede',
    '#979797',
    '#3d3d3d',
  ],
  greenTheme: '#367c2b',
  darkGreen: '#1A3D15',
  red: '#f00101',
  green: '#008411',
  yellow: '#ffcc32',
  lightGreen: '#7dc244',
  blue: '#6a75ab',
};

export const colors = {
  ...baseColors,
  superLightGrey: baseColors.gray[1],
  lightGray: baseColors.gray[2],
  darkGray: baseColors.gray[4],
  text: baseColors.gray[4],
  background: baseColors.white,
  primary: baseColors.greenTheme,
  secondary: baseColors.lightGreen,
  muted: baseColors.gray[1],
  success: baseColors.green,
  info: baseColors.greenTheme,
  warning: baseColors.yellow,
  danger: baseColors.red,
  light: baseColors.gray[2],
  dark: baseColors.gray[4],
  textMuted: baseColors.gray[3],
};

export const space = [0, 0.25, 0.5, 1, 1.5, 3].map(n => `${n}rem`);

export const breakpoints = ['576px', '768px', '992px'];

export const fonts = {
  body: "'Helvetica Neue', Helvetica, 'Encode Sans', sans-serif",
  heading: 'Open Sans, sans-serif',
  monospace: 'Menlo, monospace',
};

export const fontWeights = {
  body: 400,
  heading: 400,
  headingAlt: 600,
  bold: 700,
  light: 300,
  normal: 400,
  display: 300,
};

export const fontSizes = [
  '0.75rem', // 12px (80%),
  '0.875rem',
  '1rem', // 16px
  '1.25rem',
  '1.5rem',
  '1.75rem',
  '2rem', // 32px
  '2.5rem',
  '3.5rem', // 56px
  '4.5rem',
  '5.5rem',
  '6rem',
];

export const lineHeights = {
  body: 1.5,
  heading: 1.5,
  paragraph: 1.75,
};

export const sizes = {
  // container widths
  sm: '540px',
  md: '720px',
  lg: '1150px',
  xl: '1440px',
};

export const radii = {
  default: '0.25rem',
  sm: '0.2rem',
  lg: '0.3rem',
  pill: '50rem',
};

export const shadows = {
  default: '0 .5rem 1rem rgba(0, 0, 0, .15)',
  sm: '0 .125rem .25rem rgba(0, 0, 0, .075)',
  lg: '0 1rem 3rem rgba(0, 0, 0, .175)',
};

const heading = {
  fontFamily: fonts.heading,
  fontWeight: fontWeights.heading,
  lineHeight: lineHeights.heading,
  marginTop: '1rem',
  marginBottom: '1rem',
};

const display = {
  fontWeight: fontWeights.display,
  lineHeight: lineHeights.heading,
};

// variants
const typeStyles = {
  heading,
  display,
};

export const text = {
  caps: {
    textTransform: 'uppercase',
    letterSpacing: '0.2em',
  },
  heading: {
    fontFamily: fonts.heading,
    fontWeight: fontWeights.heading,
    lineHeight: lineHeights.heading,
    textTransform: 'uppercase',
  },
};

export const styles = {
  root: {
    fontFamily: fonts.body,
    lineHeight: lineHeights.body,
    fontWeight: fontWeights.body,
    backgroundColor: colors.white,
  },
  a: {
    color: colors.darkGray,
    textDecoration: 'underline',
    ':hover': {
      color: colors.primary,
    },
  },
  p: {
    margin: '0',
    marginBottom: '1.7rem',
    lineHeight: lineHeights.paragraph,
    fontSize: fontSizes[2],
  },
  li: {
    bulletColor: colors.lightGreen,
    bulletBorderRadius: '10px',
  },
  h1: {
    ...heading,
    fontSize: fontSizes[8],
  },
  h2: {
    ...heading,
    fontSize: fontSizes[7],
  },
  h3: {
    ...heading,
    fontSize: fontSizes[6],
  },
  h4: {
    ...heading,
    fontSize: fontSizes[4],
  },
  h5: {
    ...heading,
    fontSize: fontSizes[3],
  },
  h6: {
    ...heading,
    fontSize: fontSizes[2],
  },
  blockquote: {
    backgroundColor: colors.greenTheme,
    color: colors.background,
    textTransform: 'normal',
    fontWeight: 'bold',
    fontStyle: 'normal',
  },
  table: {
    // todo
    width: '100%',
    marginBottom: '3rem',
    color: colors.gray[3],
    borderCollapse: 'collapse',
  },
  th: {
    verticalAlign: 'bottom',
    borderTopWidth: '2px',
    borderTopStyle: 'solid',
    borderTopColor: colors.gray[3],
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    borderBottomColor: colors.gray[3],
    padding: '.75rem',
    textAlign: 'inherit',
  },
  td: {
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.gray[3],
    verticalAlign: 'top',
    padding: '.75rem',
  },
  inlineCode: {
    color: colors.darkGray,
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
  },
};

const buttonDefaults = {
  fontFamily: fonts.body,
  cursor: 'pointer',
  textTransform: 'uppercase',
  fontSize: fontSizes[0],
  fontWeight: fontWeights.bold,
  height: '48px',
  minWidth: '194px',
  borderRadius: '24px',
  transition:
    'background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), color 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
};

export const buttons = {
  primary: {
    ...buttonDefaults,
    backgroundColor: colors.blue,
    color: colors.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.blue,
    ':hover': {
      backgroundColor: 'transparent',
      color: colors.black,
    },
  },
  'primary-with-background': {
    ...buttonDefaults,
    backgroundColor: colors.white,
    color: colors.darkGray,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.white,
    ':hover': {
      backgroundColor: 'transparent',
      color: 'white',
    },
  },
  secondary: {
    ...buttonDefaults,
    backgroundColor: colors.primary,
    color: colors.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.primary,
    ':hover': {
      backgroundColor: 'transparent',
      color: colors.black,
    },
  },
  disabled: {
    ...buttonDefaults,
    cursor: 'default',
    backgroundColor: colors.muted,
    color: colors.textMuted,
  },
};

export const header = {
  backgroundColor: colors.white,
  color: colors.darkGray,
  activeColor: colors.lightGreen,
};

export const headerBottom = {
  backgroundColor: colors.greenTheme,
  color: colors.white,
  activeColor: colors.white,
  fontWeight: 'normal',
  activeFontWeight: 'bold',
  fontSize: '12px',
};

export const mobileNavigation = {
  chevronColor: colors.lightGreen,
  arrowColor: colors.lightGreen,
};

export const footer = {
  backgroundColor: colors.white,
  color: colors.darkGray,
  activeColor: colors.lightGreen,
  minHeight: '160px',
};

export const cardCtas = {
  color: colors.greenTheme,
  hoverColor: colors.darkGreen,
  arrowColor: colors.greenTheme,
  arrowHoverColor: colors.darkGreen,
  textTransform: 'uppercase',
  fontSize: '0.75rem',
  lineHeight: '13px',
  letterSpacing: '2px',
};

export const documentDownload = {
  color: colors.greenTheme,
  hoverColor: colors.darkGreen,
  arrowColor: colors.greenTheme,
  arrowHoverColor: colors.darkGreen,
};

export const formInputs = {
  activeColor: colors.lightGreen,
  borderRadius: '24px',
  radioInputBorderRadius: '50%',
  radioSpanBorderRadius: '50%',
};

export const categoryCards = {
  backgroundColor: colors.greenTheme,
};

export const search = {
  borderRadius: '20px',
  activeColor: colors.lightGreen,
};

export const ctaSection = {
  backgroundColor: colors.primary,
  color: colors.white,
};

export const shortBanner = {
  backgroundColor: colors.primary,
  color: colors.white,
  titleTextTransform: 'none',
  titleFontWeight: 'bold',
};

export const upComingEvents = {
  backgroundColor: colors.primary,
  color: colors.white,
};

export const infoSection = {
  backgroundColor: colors.primary,
  color: colors.white,
};

export const ingredients = {
  backgroundColor: colors.primary,
  color: colors.white,
  hoverColor: colors.secondary,
};

export const salesPerson = {
  borderColor: colors.primary,
};

export const dealer = {
  textColor: colors.primary,
};

export const navItems = {
  fontSize: '0.75rem',
  letterSpacing: '2px',
};

export const imageAndText = {
  backgroundColor: colors.primary,
  color: colors.white,
};

export const media = {
  titleFontWeight: 'bold',
  titleTextTransform: 'normal',
};

export const table = {
  headerBackgroundColor: colors.primary,
  headerColor: colors.white,
  scrollbarColor: colors.secondary,
};

export const customBlockCards = {
  backgroundColor: colors.lightGreen,
};

export const adviceCardModule = {
  backgroundColor: colors.primary,
};

export const contacts = {
  office: {
    mapTextTransform: 'uppercase',
    mapTextFontSize: '0.875rem',
    mapTextLetterSpacing: '2.33px',
    mapTextColor: colors.primary,
    mapTextFontWeight: 'normal',
    mapTextLineHeight: '1.71',
    openingHoursFontSize: '1rem',
  },
};

export const heroSection = {
  titleFontWeight: 'normal',
};

export const newsListing = {
  titleFontWeight: 'normal',
};

export const productListing = {
  titleFontWeight: 'normal',
};

export const cookieBanner = {
  backgroundColor: colors.primary,
};

export default {
  name: 'Alliance',
  logo: Logo,
  breakpoints,
  text,
  colors,
  cookieBanner,
  space,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  sizes,
  shadows,
  radii,
  typeStyles,
  header,
  headerBottom,
  mobileNavigation,
  footer,
  styles,
  buttons,
  cardCtas,
  documentDownload,
  formInputs,
  categoryCards,
  search,
  ctaSection,
  upComingEvents,
  ingredients,
  salesPerson,
  infoSection,
  dealer,
  navItems,
  imageAndText,
  shortBanner,
  media,
  table,
  customBlockCards,
  adviceCardModule,
  contacts,
  heroSection,
  newsListing,
  productListing,
};
