import { LocaleType } from './LocaleType';

const locales: Record<string, LocaleType> = {
  en: {
    id: 'en',
    name: 'English',
    path: 'en',
  },
  fr: {
    id: 'fr',
    name: 'French',
    path: 'fr',
  },
  nl: {
    id: 'nl',
    name: 'Dutch',
    path: 'nl',
  },
  de: {
    id: 'de',
    name: 'German',
    path: 'de',
  },
  es: {
    id: 'es',
    name: 'Español ',
    path: 'es',
  },
  pl: {
    id: 'pl',
    name: 'Polski',
    path: 'pl',
  },
  pt: {
    id: 'pt',
    name: 'Português',
    path: 'pt',
  },
};

export default locales;
