/* eslint-disable @typescript-eslint/no-explicit-any */
import { getCurrentLangKey } from 'ptz-i18n';
import React, { ReactNode } from 'react';
import Helmet from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import { IntlContextProvider, getTranslations, locales } from '../../i18n';
import { LocaleType } from '../../i18n/LocaleType';
import themes from '../../themes';

import GlobalStyle from './GlobalStyle';
import { LocationContextProvider } from './Location';

type Props = {
  children: ReactNode;
  location: any;
  data: any;
  theme: string;
  enabledLocales: Array<string>;
  defaultLocaleId: string;
};

export const EnabledLocalesContext = React.createContext({} as any);
export const SiteDataContext = React.createContext({} as any);

const PageWrapper = ({
  children,
  location,
  data,
  theme,
  enabledLocales,
  defaultLocaleId = 'en',
}: Props): JSX.Element => {
  const siteData = (data && data.contentfulSettings) || {};
  const filteredLocales: Record<string, LocaleType> = {};
  const availableLangs: Array<string> = [];
  let defaultLocale: LocaleType = locales.en;

  Object.keys(locales)
    .filter(id => enabledLocales.includes(id))
    .forEach(id => {
      filteredLocales[id] = locales[id];
      availableLangs.push(locales[id].path);
      if (defaultLocaleId === id) {
        defaultLocale = locales[id];
      }
    });

  const currentLangKey = getCurrentLangKey(availableLangs, defaultLocaleId, location.pathname);
  const currentLocale =
    Object.keys(filteredLocales)
      .map((id: string) => filteredLocales[id])
      .find((locale: LocaleType) => locale.path === currentLangKey) ||
    defaultLocale ||
    locales.en;

  const i18nMessages = getTranslations(currentLocale.id || defaultLocale.id, theme);

  return (
    <IntlProvider locale={currentLocale.id} messages={i18nMessages} key={currentLocale.id}>
      <EnabledLocalesContext.Provider value={filteredLocales}>
        <IntlContextProvider value={currentLocale}>
          <LocationContextProvider value={location}>
            <SiteDataContext.Provider value={siteData}>
              <ThemeProvider theme={themes[theme]}>
                <GlobalStyle />
                <Helmet htmlAttributes={{ lang: currentLocale.id }} />
                {children}
              </ThemeProvider>
            </SiteDataContext.Provider>
          </LocationContextProvider>
        </IntlContextProvider>
      </EnabledLocalesContext.Provider>
    </IntlProvider>
  );
};

export default PageWrapper;
