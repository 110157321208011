export default {
  'articleListing.applyFilters': 'Filters toepassen',
  'articleListing.filters': 'Filters',
  'articleListing.loadMore': 'Laad meer',
  'brandListing.all': 'Toon alles',
  'brandListing.byCategories': 'Per categorie',
  'brandListing.clear': 'Wissen',
  'brandListing.productsAndServices': 'Producten & Services',
  'brandListing.seeProductsFor': 'Bekijk producten',
  'brandListing.similarBrands': 'Brands met gelijkaardige producten & services',
  'brandListing.visitWebsite': 'Bekijk website',
  'cards.advice.link': 'Lees artikel',
  'cards.category.link': 'Lees meer',
  'cards.event.link': 'Bekijk event',
  'cards.news.link': 'Lees verder',
  'cards.product.link': 'Bekijk meer',
  'footer.copyright': '© {year} Arvesta. All rights reserved.',
  'footer.follow_us': 'Volg ons',
  'footer.newsletter.error': 'Er is een fout opgetreden met de informatie die je hebt ingevoerd, probeer het opnieuw.',
  'footer.newsletter.heading':
    'Wil jij nieuwtjes en advies ontvangen die aansluiten bij wat jóu interesseert? Doen we graag voor je!',
  'footer.newsletter.placeholder': 'Vul je email adres in',
  'footer.newsletter.subscribe': 'Inschrijven',
  'footer.newsletter.success': 'Dank je wel. We hebben je gegevens goed genoteerd. Tot binnenkort!',
  'footer.subscribe.belgium': 'België',
  'footer.subscribe.countryLabel': 'Land',
  'footer.subscribe.enteremail': 'Vul je email adres in',
  'footer.subscribe.failure': 'Er is een fout opgetreden met de informatie die je hebt ingevoerd, probeer het opnieuw.',
  'footer.subscribe.invalid_country': 'Kan je nog even je land ingeven?',
  'footer.subscribe.invalid_email': 'Kan je nog even je e-mailadres ingeven?',
  'footer.subscribe.netherlands': 'Nederland',
  'footer.subscribe.subscribe': 'Inschrijven',
  'footer.subscribe.subscribing': 'Even geduld aub, we proberen verbinding te maken met de server',
  'footer.subscribe.success': 'Dank je wel. We hebben je gegevens goed genoteerd. Tot binnenkort!',
  'forms.contact.description': 'Vul het onderstaande formulier in en ons team neemt contact met u op.',
  'forms.contact.email.label': 'E-mailadres',
  'forms.contact.email.placeholder': 'Vul je e-mailadres in',
  'forms.contact.errors.email': 'Kan je nog even je e-mailadres ingeven?',
  'forms.contact.errors.required': 'Vul je nog even dit verplichte veld in?',
  'forms.contact.failure': 'Er is een fout opgetreden met de informatie die je hebt ingevoerd, probeer het opnieuw.',
  'forms.contact.fullname.label': 'Voor-en achternaam',
  'forms.contact.fullname.placeholder': 'Vul je volledige naam in',
  'forms.contact.message.label': 'Bericht',
  'forms.contact.message.placeholder': 'Vul je bericht in ',
  'forms.contact.newsletter.title':
    'Wil jij nieuwtjes en advies ontvangen die aansluiten bij wat jóu interesseert? Doen we graag voor je!',
  'forms.contact.pageTitle': 'Pagina titel',
  'forms.contact.submit': 'Versturen',
  'forms.contact.success': 'Dank je. We bekijken je bericht.',
  'forms.contact.title': 'Neem contact met ons op ',
  'globals.download': 'Download',
  'globals.featuredProducts': 'aanbevolen producten',
  'globals.no': 'Neen',
  'globals.yes': 'Ja',
  'header.search.placeholder': 'zoeken',
  'header.selectlanguage': 'Selecteer Taal',
  'header.allAbout': 'Alles over',
  'legacy.cookie.description': 'Met het bezoeken van deze website gaat u akkoord met ons gebruik van',
  'legacy.footer.headoffice': 'Hoofdkantoor',
  'legacy.footer.headquarters': 'Maatschappelijke zetel',
  'legacy.header.about': 'over ons',
  'legacy.header.contact': 'contacteer ons',
  'legacy.header.jobs': 'jobs',
  'legacy.header.news': 'nieuws & pers',
  'legacy.header.products': 'producten & diensten',
  'newscard.readmore': 'Lees meer',
  'newsletter.email.error': 'Gelieve een geldig e-mailadres in the geven',
  'newsletter.email.success': 'Je hebt je succesvol ingeschreven. Je hoort spoedig van ons.',
  'newsletter.input.placeholder': 'E-mailadres',
  'newsletter.terms.error': 'Gelieve akkoord te gaan met de algemene privacy voorwaarden',
  'newsletter.terms.privacyPolicy': 'privacyverklaring',
  'newsletter.terms.sectionOne': 'Ik erken de ',
  'newsletter.terms.sectionTwo': 'ontvangen en gelezen te hebben.',
  'newsletter.terms.subscribe': 'Inschrijven',
  'product.certifications.title': 'Productcertificeringen',
  'sections.advice.loadmore': 'Laad meer advies',
  'sections.category.loadmore': 'laad meer',
  'sections.contact.offices': 'kantoren',
  'sections.contact.openinghours': 'openingstijden',
  'sections.contact.salesteam': 'Team',
  'sections.contact.viewonmap': 'Bekijk op google maps',
  'sections.dealer.clear': 'Wissen',
  'sections.dealer.invalidaddress': 'We konden dit adres niet vinden',
  'sections.dealer.loadmore': 'Toon alles',
  'sections.dealer.maplink': 'Bekijk op google maps',
  'sections.dealer.placeholder': 'Vul je postcode en gemeente in',
  'sections.dealer.search': 'Zoeken',
  'sections.dealer.total': 'resultaten voor',
  'sections.events.loadmore': 'Laad meer evenementen',
  'sections.events.loadmorepast': 'Laad meer evenementen uit het verleden',
  'sections.news.loadmore': 'Laad meer nieuwsitems',
  'sections.product.loadmore': 'Laad meer',
  'sections.product.noproductsfoundfor': 'geen producten gevonden voor',
  'sections.product.table.brochure': 'brochure',
  'sections.product.table.download': 'Download',
  'sections.product.table.number': 'art.nr',
  'sections.product.table.product': 'product',
  'sections.product.table.weight': 'gewicht',
  'sections.search.events': 'Evenementen',
  'sections.search.news': 'Nieuws',
  'sections.search.noncategorised': 'Niet gecategoriseerd',
  'sections.search.noresults.title': 'Sorry we hebben geen resultaten gevonden voor ',
  'sections.search.products': 'products',
  'sections.search.results.title': '{total} resultaten voor {query}',
  'sections.search.seeallevents': 'Alle evenementen bekijken',
  'sections.search.seeallnews': 'Alle nieuws bekijken',
  'sections.search.seeallproducts': 'Alle producten bekijken',
};
