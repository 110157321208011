import React from 'react';

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450.3 256.4" width="162px" height="50px">
    <g>
      <g>
        <path fill="#059370" d="M450.3,91.2C139.6-58.5,25.5-26.2,0,204.5C350.5,322.2,422.2,220.4,450.3,91" />
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M363.3,112.9c-6.6-7.1-15.1-9.7-24.7-8.2c-9.2,1.4-16.3,6.3-20.7,14.7c-4.8,9.3-5.2,18.9-1.6,28.7
			c2.9,7.8,8.3,13.3,16.1,16.3c6.5,2.5,13.3,2.9,20.1,1.4c5.7-1.2,10.7-3.7,14.9-7.8c0.9-0.9,1-1.5,0.1-2.5
			c-0.3-0.4-0.8-0.9-1.2-1.4c-3.5-4-9.1-5.4-14-3.5c-0.9,0.3-1.8,0.6-2.7,0.7c-7.7,1.8-14.2-1.7-16.5-8.7c-0.5-1.6-0.4-1.8,1.3-1.8
			c5.7,0,11.4,0,17.2,0c5.8,0,11.5,0,17.3,0c1,0,1.7-0.2,1.9-1.4C372.6,129.5,370.4,120.4,363.3,112.9 M352.4,129.5
			c-3.1,0-6.2,0-9.3,0c-3.1,0-6.1,0-9.2,0c-0.6,0-1.3,0.1-1.1-0.9c0.9-4,3.6-8.3,8-9.2c7.2-1.5,11.5,2.9,12.8,9
			C353.9,129.4,353.2,129.5,352.4,129.5 M313.5,108.4c1-2.7,1-2.7-1.8-2.7h-8.2c-4.6,0-8.6,2.9-10.1,7.3c-2.6,7.7-6.6,19.6-8.6,26.3
			c-0.5,1.6-1,3.1-1.6,4.8c-0.5-0.4-0.6-0.8-0.7-1.2c-1.5-4.7-3-9.4-4.5-14.2c-1.2-3.8-3-9.9-4.5-14.9c-1.4-4.8-5.8-8.1-10.8-8.1
			c-3.9-0.1-8.1-0.1-9.6-0.1c-1.1,0-1.4,0.5-0.9,1.5c0.1,0.2,0.1,0.4,0.2,0.5c4.8,13.1,13,35.9,17.6,48.5c2,5.5,7.2,9.1,13,9.1
			c5.7,0,10.7-3.5,12.8-8.7c0.7-1.9,1.4-3.7,1.9-4.9C303,137.2,308.2,122.8,313.5,108.4 M244.1,112.9c-6.6-7.1-15.1-9.7-24.7-8.2
			c-9.3,1.4-16.3,6.3-20.7,14.7c-4.8,9.3-5.2,18.9-1.6,28.7c2.9,7.8,8.3,13.3,16.1,16.3c6.5,2.5,13.3,2.9,20.1,1.4
			c5.7-1.2,10.7-3.7,14.9-7.8c0.9-0.9,1-1.5,0.1-2.5c-0.4-0.4-0.8-0.9-1.2-1.4c-3.5-4-9.1-5.4-14-3.5c-0.9,0.3-1.8,0.6-2.7,0.7
			c-7.7,1.8-14.2-1.7-16.5-8.7c-0.5-1.6-0.4-1.8,1.3-1.8c5.7,0,11.4,0,17.1,0c5.8,0,11.5,0,17.3,0c1,0,1.7-0.2,1.9-1.4
			C253.4,129.5,251.1,120.4,244.1,112.9 M233.1,129.5c-3.1,0-6.2,0-9.3,0c-3.1,0-6.1,0-9.2,0c-0.6,0-1.3,0.1-1.1-0.9
			c0.9-4,3.6-8.3,8-9.2c7.2-1.5,11.5,2.9,12.8,9C234.6,129.4,233.9,129.5,233.1,129.5 M194.5,108.4c1-2.7,1-2.7-1.8-2.7h-8.2
			c-4.6,0-8.6,2.9-10.1,7.3c-2.6,7.7-6.6,19.6-8.6,26.3c-0.5,1.6-1,3.1-1.6,4.8c-0.5-0.4-0.6-0.8-0.7-1.2c-1.5-4.7-3-9.4-4.5-14.2
			c-1.2-3.8-3-9.9-4.5-14.9c-1.4-4.8-5.8-8.1-10.8-8.1c-3.9-0.1-8.1-0.1-9.6-0.1c-1.1,0-1.4,0.5-0.9,1.5c0.1,0.2,0.1,0.4,0.2,0.5
			c4.8,13.1,13,35.9,17.6,48.5c2,5.5,7.2,9.1,13,9.1c5.7,0,10.7-3.5,12.8-8.7c0.7-1.9,1.4-3.7,1.9-4.9
			C184,137.2,189.2,122.8,194.5,108.4 M149.4,180.3c-8-21.1-24.5-70.3-32.2-87.2c-4-8.7-8.8-9.4-13.1-9.5c-3.6-0.1-6.1,0.2-8.8,0.1
			c-1.6,0-2.3,0.5-2.9,2c-1.8,4.9-3.7,9.7-5.6,14.5l0,0c-1.5,3.9-3.1,7.9-4.6,12.1c-0.8,2-1.5,3.9-2.3,5.9c-3,7.8-6,15.6-9,23.5h0.1
			c-2.5,6.6-4.8,12.5-6.6,17c-0.7,1.7-0.7,1.8,1.2,1.8c1.6,0,5.9,0.3,10,0.4c3.8,0.1,7.3-2.1,8.7-5.6c0.6-1.5,2.2-5.9,4.3-11.7
			c2.1,0,14,0,17.7,0c1.5,0,1.5,0,1-1.4c-0.3-0.8-2.6-7.5-4.4-12.7h-9.1c1.6-4.5,3.3-9,4.8-13c1.6-4,3-7.7,4.2-11
			c0.1-0.3,0.2-0.5,0.5-1c1.3,3.8,2.5,7.4,3.8,10.9c0.4,1.2,2.2,6.4,4.6,13.1l5.5,15.9c5,14.4,10.2,29.2,11.2,32
			c1.4,3.8,5.1,6.2,9.2,6.1c4.4,0,8.9-0.3,10.6-0.3C150,182.2,150.1,182.2,149.4,180.3"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
